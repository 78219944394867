/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import withRoleRequired from '../../../../lib/withRoleRequired';
import {
  Scenario,
  SWOTQueryData,
  SWOT,
  AnalysisQueryData,
  Analysis,
} from '../../../../types/Global';
import { mapEdgesToNodes } from '../../../../lib/helpers';
import SWOTPage from '../../../../components/swot/swotPage';

interface Props {
  data: {
    scenariosCsv: Scenario;
    allAnalysesCsv: AnalysisQueryData[];
    allSwotCsv: SWOTQueryData;
  };
  pageContext: {
    slug: string;
    analysisSlug: string;
  };
  params: {
    analysis: string;
  };
}

const ScenarioSWOTPageTemplate: React.FunctionComponent<Props> = ({
  data,
  params,
}) => {
  const swots: SWOT[] = mapEdgesToNodes(data.allSwotCsv) || [];
  const analyses: Analysis[] = mapEdgesToNodes(data.allAnalysesCsv);
  const thisAnalysis = analyses.find(
    (analysis) => analysis.slug === params.analysis
  );
  if (!thisAnalysis) return null;
  return (
    <SWOTPage
      scenario={data.scenariosCsv}
      analysis={analyses[0]}
      swots={swots}
      indexInBreadcrumbs
    />
  );
};

export const pageQuery = graphql`
  query($scenario: String, $analysis: String) {
    allAnalysesCsv {
      edges {
        node {
          slug
          image
          description
        }
      }
    }
    allSwotCsv(
      filter: { scenario: { eq: $scenario }, analysis: { eq: $analysis } }
    ) {
      edges {
        node {
          scenario
          analysis
          swotTitle
          category
          title
          description
        }
      }
    }
    scenariosCsv(slug: { eq: $scenario }) {
      title
      slug
      narrativeImage
      narrativeTitle
      dataImage
      dataTitle
      personasImage
      personasTitle
    }
  }
`;

export default withRoleRequired(ScenarioSWOTPageTemplate);
